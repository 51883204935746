export default {
    de: {
        appTitle: "Offene Stellen",
        openPositions: "offene-stellen",
        loginText: "Bereits beworben? Hier geht es zu Ihrem Bewerberprofil. ",
        loginLabel: "Anmelden",
        loginUrl: "https://recruitingapp-2624.umantis.com/SelfService/MyProfile",
        currentJobs: "Aktuelle Stellen",
        quickButton: "Quickbewerbung Pflege/MTT",
        quickButtonUrl: "https://arbeiten-mit-den-besten.ch/quick-bewerbung/",
        companyTitle: "Inselspital",
        altSearchFilter: "Icon - Suchfilter",
        altJobabo: "Bild - Jobabo",
        altJobaboCancel: "Bild - Jobabo schliessen",
        altSpontanbewerbung: "Bild - Spontanbewerbung",
        altPersonalvermittlerinnen: "Bild - Personalvermittlerinnen",
        footerJobaboTitle: "Job-Abo",
        footerJobaboText: "Bleiben Sie über neue Stellen informiert. Wir senden Ihnen eine E-Mail, sobald eine neue Stelle ausgeschrieben wird.",
        footerSpontanbewerbungTitle: "Spontanbewerbung",
        footerSpontanbewerbungText: "Keine passende Stelle dabei? Dann sind wir gespannt auf Ihre Spontanbewerbung.",
        footerSpontanbewerbungUrl: "https://www.inselgruppe.ch/de/jobs-und-karriere/offene-stellen/spontanbewerbung/",
        footerPersonalvermittlerinnenTitle: "Personalvermittler:innen",
        footerPersonalvermittlerinnenText: "Sie möchten uns Kandidat:innen vermitteln?",
        footerPersonalvermittlerinnenUrl: "https://www.inselgruppe.ch/de/jobs-und-karriere/offene-stellen/login-fuer-personalvermittler",
        searchTitle: "Suche verfeinern",
        searchPlaceholder: "Suchbegriff eingeben",
        searchPlaceholderJobAbo: "Suchbegriff",
        filterProfession: "Berufsgruppe",
        filterLocation: "Arbeitsort",
        filterLocationTop: "Alle",
        showFilters: "Filter anzeigen",
        hideFilters: "Filter schliessen",
        resetFilters: "Filter zurücksetzen",
        submitFilters: "Suchen",
        noJobs: "Suche ergibt keinen Treffer",
        workloadLabel: "Pensum",
        jobaboButtonUrl: "https://ohws.prospective.ch/public/v1/careercenter/1000666/jobabo?lang=de",
        jobaboModalButtonCloseLabel: "Jobabo schliessen",
        jobaboModalButtonLabel: "Suche abonnieren",
        loadmorejobs: "Weitere Jobs",
        favJobsSendByEmail: "Per E-Mail versenden",
        favJobsRemoveAll: "Alle löschen",
        favJobsDrawerHeader: "Jobs gemerkt",
        favJobsEmailPrebody:
            "Schön, dass du dich für einen Job bei der Halter Gruppe interessierst.\n" +
            "\n" +
            "Hier findest du deine ausgewählten Stellen:",
        favJobsEmailPostbody:
            "Wir freuen uns, wenn du dich bei uns bewirbst. Bei Fragen zur Stelle, melde dich gern bei der im Inserat angegebenen Kontaktperson.\n" +
            "\n" +
            "Vielleicht treffen wir uns ja schon bald persönlich bei der Halter Gruppe.\n" +
            "\n" +
            "Viele Grüsse\n" +
            "Dein Halter Gruppe Recruiting Team",
        jobaboEmailLabel: "E-Mail Adresse",
        jobaboNameLabel: "Betreff des Job-Abo Mails",
        jobaboSubscriptionFormTitle: "Job-Abo Anmeldung",
        jobaboSubscriptionFormIntroduction: "Wollen Sie regelmässig über neue Stellenangebote der Insel Gruppe informiert werden? Eröffnen Sie Ihr individuelles Stellensuchprofil und Sie werden automatisch über passende Stellenangebote informiert.",
        displayAsGrid: "Als Gitter anzeigen",
        jobGridColumns: "Anzahl Spalten",
        error:
            "Leider ist beim Laden der Seite ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
        zivildienststellen:
            "Zivildienststellen: Sie suchen einen kurzfristigen Einsatz bei uns für Ihre Zivildienstzeit? Bitte bewerben Sie sich via E-ZIVI.",
        zivildienststellenLink: "https://www.zivi.admin.ch/de/ziviconnect-de",
        mehrerfahren: "Mehr dazu",
    },
    fr: {
        appTitle: "Postes vacants",
        openPositions: "postes-vacants",
        loginText: "Vous avez déjà posé votre candidature? Cliquez ici pour accéder à votre profil de candidat. ",
        loginLabel: "Inscription",
        loginUrl: "https://recruitingapp-2624.umantis.com/SelfService/MyProfile",
        currentJobs: "Postes actuels",
        quickButton: "Candidature rapide Soins/MTT",
        companyTitle: "Inselspital",
        altSearchFilter: "Icon - Filtre de recherche",
        altJobabo: "Image - Jobabo",
        altJobaboCancel: "Image - Jobabo fermer",
        altSpontanbewerbung: "Image - Candidature spontanée",
        altPersonalvermittlerinnen: "Image - Recruteuses",
        footerJobaboTitle: "Job-Abo",
        footerJobaboText: "Restez informé des nouveaux postes. Nous vous envoyons un e-mail dès qu'un nouveau poste est publié.",
        footerSpontanbewerbungTitle: "Candidature spontanée",
        footerSpontanbewerbungText: "Aucun poste ne vous convient? Dans ce cas, nous attendons avec impatience votre candidature spontanée.",
        footerSpontanbewerbungUrl: "https://www.inselgruppe.ch/de/jobs-und-karriere/offene-stellen/spontanbewerbung/",
        footerPersonalvermittlerinnenTitle: "Agences de recrutement",
        footerPersonalvermittlerinnenText: "Vous souhaitez nous présenter des candidats?",
        footerPersonalvermittlerinnenUrl: "https://www.inselgruppe.ch/fr/emplois-et-carriere/offres-demploi/login-pour-agences-de-placement",
        searchTitle: "Affiner la recherche",
        searchPlaceholder: "Saisir un terme de recherche",
        searchPlaceholderJobAbo: "Terme de recherche",
        filterProfession: "L'unité d'affaires",
        filterLocation: "Lieu de travail",
        filterLocationTop: "Tous les détails",
        showFilters: "Afficher les filtres",
        hideFilters: "Masquer les filtres",
        resetFilters: "Réinitialiser les filtres",
        submitFilters: "Envoyer",
        noJobs: "La recherche ne donne aucun résultat",
        workloadLabel: "Charge de travail",
        jobaboButtonUrl: "https://ohws.prospective.ch/public/v1/careercenter/1000666/jobabo?lang=fr",
        jobaboModalButtonCloseLabel: "Fermer Jobabo",
        jobaboModalButtonLabel: "S'abonner à la recherche",
        loadmorejobs: "Charger plus d'emplois",
        favJobsSendByEmail: "Envoyer par courrier électronique",
        favJobsRemoveAll: "Supprimer tout",
        favJobsDrawerHeader: "Emplois notés",
        favJobsEmailPrebody:
            "Je suis heureux que tu t'intéresses à un emploi à la Halter Gruppe.\n" +
            "\n" +
            "Tu trouveras ici les postes que tu as sélectionnés:",
        favJobsEmailPostbody:
            "Nous serions ravis que tu nous envoies ta candidature. Si tu as des questions sur le poste, n'hésite pas à contacter la personne de contact indiquée dans l'annonce.\n" +
            "\n" +
            "Nous nous rencontrerons peut-être bientôt en personne à la Halter Gruppe.\n" +
            "\n" +
            "Salutations\n" +
            "L'équipe de recrutement d'Halter Gruppe",
        jobaboEmailLabel: "Adresse e-mail",
        jobaboNameLabel: "Sujet du mail d'abonnement à l'emploi",
        jobaboSubscriptionFormTitle: "S'abonner aux nouveaux emplois",
        jobaboSubscriptionFormIntroduction: "Voulez-vous être régulièrement informé des nouvelles offres d'emploi par la Insel Gruppe? Ouvrez votre profil de recherche d'emploi individuel et vous serez automatiquement informé des offres d'emploi appropriées.",
        displayAsGrid: "Affichage sous forme de grille",
        jobGridColumns: "Nombre de colonnes",
        error:
            "Malheureusement, une erreur s'est produite lors du chargement de la page. Veuillez réessayer plus tard.",
    },
};