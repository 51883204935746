import { useEffect, useState } from "react";

import { useSettings } from "./SettingsContext";

/*   Example object:
  filtersParams = {
    lang: "de",
    q: "Art",
    offset: 0,
    limit: 10,
    workload: [10,100],
    f: {
      10: [12345, 56789],
      20:  [...]
    }
  }
  
  options = [{
    id: 10,
    value: 12345,
    label: "Filter 10 - Label 12345",
    has_children: true
    children_filter_id: '10_12345'
  }, {
    id: 10,
    value: 56789,
    label: "Filter 10 - Child Label 1234 > 56789",
    has_children: false
    children_filter_id: '10_12345_56789'
  }, {
    id: 20,
    ...
  }]

 */

// /**
//  * Converts a filtersParams object to an options object for the Select (Dropdowncomponent
//  * @param {*} filtersParams
//  */
// const filtersParamsToOptions = (filtersParams) => {
//   const newOptions = [];

//   for (const filterId in filtersParams.f) {
//     if (filtersParams.f.hasOwnProperty(filterId)) {
//       for (const filterValue of filtersParams.f[filterId]) {
//         const option = {
//           id: parseInt(filterId, 10),
//           value: filterValue,
//           label: `Filter ${filterId} - Label ${filterValue}`,
//           has_children: false,
//           children_filter_id: `${filterId}_${filterValue}`,
//         };

//         newOptions.push(option);
//       }
//     }
//   }

//   return newOptions;
// };

// /**
//  * Turns
//  * @param {*} options
//  * @returns
//  */
// const optionsToFiltersParams = (options) => {
//   const filtersParams = {
//     f: {},
//   };

//   options.forEach((option) => {
//     if (!filtersParams.f.hasOwnProperty(option.id)) {
//       filtersParams.f[option.id] = [];
//     }
//     filtersParams.f[option.id].push(option.value);
//   });

//   return filtersParams;
// };

/**
 * Converts an Url query String (?lang=de&q=...&f=70:1216240&f=80:1218652&workload=10,80...) to a filtersParams object
 * @param {string} queryString
 * @param {object} settings - settings object, for defaults (e.g. jobsPageSize)
 * @returns filter params object
 */
const queryStringToFiltersParams = (queryString) => {
  const queryParams = new URLSearchParams(queryString); //getQueryStringParams(queryString);
  const { settings } = useSettings();

  const filtersParams = {
    lang: queryParams.get("lang") || "de",
    q: queryParams.get("q") || null,
    offset: parseInt(queryParams.get("offset")) || 0,
    limit: parseInt(queryParams.get("limit")) || settings.jobsPageSize || 5,
    workload:
      queryParams
        .get("workload")
        ?.split(",")
        .map((d) => parseInt(d)) || null,
    // filter params is empty, parsed in next step
    f: {},
    sort: queryParams.get ("sort") || ""
  };

  // as "f" param is an array, we need to parse it manually f=10:12345,56789&f=20:...
  queryParams.getAll("f").map((param) => {
    const [filterId, filterValues] = param.split(":"); // e.g. f=10:12345,56789
    filtersParams.f[filterId] = filterValues.split(","); // e.g. filtersParams.f[10] = [12345,56789]
  });

  // Example object:
  // filtersParams = {
  //   lang: "de",
  //   q: "Art",
  //   offset: 0,
  //   limit: 10,
  //   workload: [10,100],
  //   f: {
  //     10: [12345, 56789],
  //     20:  [...]
  //   },
  //   sort: "80"
  // }

  return filtersParams;
};

/**
 * Hook to provide current filter parameters, initialized from current location url
 * @returns
 */
function useUrlParams() {
  // extract filters Params from current location url
  return queryStringToFiltersParams(window.location.search);
}

/**
 *
 * @param {filter Params object} filtersParams
 * @returns query string
 */
const filtersParamsToQueryString = (filtersParams) => {
  //console.log("filtersParamsToQueryString", filtersParams);
  const { settings } = useSettings();

  let queryString = "";
  queryString += `lang=${filtersParams.lang}`;
  queryString += filtersParams.q ? `&q=${filtersParams.q}` : "";
  queryString += `&offset=${filtersParams.offset || 0}`;
  queryString += `&limit=${filtersParams.limit || settings.jobsPageSize || 5}`;
  queryString += filtersParams.workload
    ? `&workload=${filtersParams.workload}`
    : "";

  // as "f" param is an array, we need to parse it manually
  Object.keys(filtersParams.f || {}).map((filterId) => {
    const filterValues = filtersParams.f[filterId];
    queryString += `&f=${filterId}:${filterValues}`;
  });

  // add nsort param
  if (filtersParams.sort) {
    queryString += `&sort=${filtersParams.sort}`;
  }

  //console.log("queryParams", queryParams);

  return queryString.toString();
};

/**
 * Helper method created a (fully qualified) URL for fetching jobs from the backend API.
 * Uses the current filter parameters and the base URL from the settings.
 * @param {filtersParams} filtersParams
 * @returns
 */
const useFetchUrl = (filtersParams) => {
  const { settings } = useSettings();
  return (
    `${settings.baseUrl}/jobs?` + filtersParamsToQueryString(filtersParams)
  );
};

export { useFetchUrl, useUrlParams };
