import * as React from "react";
import {useSettings} from "../util/SettingsContext";
import Pagination from '@mui/material/Pagination';

/**
 * This is a reusable pagination component for Job Lists.
 * It uses the filtersParams object to get the current offset and limit (= page size) and also the total number of jobs for the query.
 * It displays a Pagination control from Material UI.
 * If you pass in a setFilterParams function, it will be called when the user clicks on a page number with the updated offset property
 * (so that you can then fetch the new page of jobs).
 *
 * @param {} filtersParams The current filtersParams object. Important properties: offset, limit
 * @param {*} setFilterParams A useState setter function for the filtersParams object
 * @param {*} totalJobs Total number of jobs for the query. This is used to calculate the number of pages.
 * @param {*} loading if True the component will display a loading indicator instead of the pagination control
 * @param {*} props Will be spread onto the Pagination component. You can use this e.g. for custom styling via sx={{...}} property.
 *
 * @returns JobListPagination component
 */
const JobListLoadMore = ({
                             filtersParams,
                             setFiltersParams,
                             totalJobs,
                             loading = false,
                             ...props
                         }) => {
    const {settings, setSettings} = useSettings();

    const currentPage = filtersParams?.offset / filtersParams?.limit + 1;
    const totalPages = Math.ceil(totalJobs / filtersParams?.limit);

    let empty = !(totalPages >= 1);

    let error =
        !filtersParams ||
        !setFiltersParams ||
        filtersParams.offset < 0 ||
        filtersParams.limit <= 0 ||
        filtersParams.offset > totalJobs;

    const handleLoadMore = () => {
        //console.log("Going to page:", actualPage);
        setFiltersParams({
            ...filtersParams,
            offset: 0,
            limit: filtersParams.limit + settings.jobsPageSize,
            submitted: true,
        });
    }

    return (
        <>
            {error && (
                <div>
                    ERROR: Invalid or missing required properties for JobListPagination
                </div>
            )}
            {(loading || empty) && <Pagination hidden={true}/>}
        </>
    );
};

export default JobListLoadMore;