/***
 *
 * This library is used to query the Career Center JobLists from our API server.
 * API docs: ...
 *
 * Uses the following libraries:
 * - react-query (useQuery hook): https://tanstack.com/query/v4/docs/react/overview
 *
 */

import { useQuery } from "@tanstack/react-query";

// import our utility functions for working with filters and languages
import { i18n } from "./i18n";

/**
 * Loads the filters (attributes) from the backend API.
 *
 * @param {*} baseUrl
 * @param {*} language
 * @returns
 */
const useLoadFilters = (baseUrl, language = "de") => {
  return useQuery(
    ["attributes"],
    () => {
      
      return fetch(
        `${baseUrl}/attributes?lang=${language}&returnValuesAsArray=1`
      )
        .then((res) => {
          
          if (!res.ok) {
            console.log("Error fetching Filter attributes from ", res.url);
            return Promise.reject(res);
          }
          return res.json();
        })
        .then((data) => {
          console.log("---");
          console.log("useLoadFilters: ", data);
          console.log("---");
          return data;
        });
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

/**
 * Loads the job list from the backend API.
 * @param {url for loading the job list} jobsFetchUrl.
 *
 * See https://tanstack.com/query/v4/docs/react/guides/queries
 *
 * @returns
 */
const useLoadJobs = (jobsFetchUrl) => {
  return useQuery(
    ["jobs", jobsFetchUrl],
    () => {
      return fetch(jobsFetchUrl)
        .then((res) => {
          if (!res.ok) {
            console.log("Error fetching jobs from ", jobsFetchUrl);
            return Promise.reject(res);
          }

          return res.json();
        })
        .then((data) => {
          console.log("---");
          console.log("useLoadJobs: ", data);
          console.log("---");

          return data;
        });
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );
};

export { useLoadFilters, useLoadJobs };
