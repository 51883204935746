import React from "react";
import Grid from "@mui/material/Grid";
import {Card, ListItemText, Popover, Skeleton, Stack, Typography, IconButton} from "@mui/material";
import Button from "@mui/material/Button";

import {i18n} from "../util/i18n";
/* images */
import imageJobabo from "../../public/assets/images/footer/Job-Abo.png";
import imageCancel from "../../public/assets/images/icons/cancel.svg";

/**
 * Renders a a JobAbo Subscriptionm Form
 * @returns JSX
 */
const JobAboSubscriptionModal = ({
                                     anchor,
                                     filtersParams,
                                     setFiltersParams,
                                     attributes = null,
                                     loading = false,
                                     ...props
                                 } = props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popoverJobaboWrapper' : undefined;

    if (loading) {
        return (
            <div>
                <Skeleton width={"100%"} height={200} variant="wave"/>
            </div>
        );
    }

    return (
        <>
            <Card className="footerCardItem">
                <Typography aria-describedby={id} component="div" onClick={handleClick}>
                    <img alt={i18n.translations.altJobabo} src={imageJobabo}/>
                    <Grid xs={12} paddingBottom="40px" paddingLeft="25px" paddingRight="25px" paddingTop="20px">
                        <ListItemText
                            primary={i18n.translations.footerJobaboTitle}
                        />
                        <ListItemText
                            secondary={i18n.translations.footerJobaboText}
                        />
                    </Grid>
                </Typography>
            </Card>
            <Popover anchorEl={anchorEl} anchorOrigin={{horizontal: 'left', vertical: 'top'}} id={id} onClose={handleClose} open={open}>
                <Stack direction={"column"}>
                    <div className="drawer-header">
                        <Grid container>
                            <Grid container xs={6} alignItems="center">
                                <Typography component="h2">
                                    {i18n.translations.jobaboSubscriptionFormTitle}
                                </Typography>
                            </Grid>
                            <Grid container xs={6} justifyContent="flex-end">
                                <IconButton sx={{width:"35px", height:"35px"}} onClick={handleClose} style={{backgroundImage: `url(${imageCancel})`, backgroundSize: 'cover',backgroundPosition: 'center',
                                }}></IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="drawer-body">
                        {props.children}
                    </div>
                </Stack>
            </Popover>
        </>
    );
};

export default JobAboSubscriptionModal;