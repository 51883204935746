import {
    Checkbox, FormLabel,
} from "@mui/material";
import React from "react";
import {i18n} from "../util/i18n";

/**
 * A flexible Checkbox component for Attribute filters.
 * Supports single or multiple selections.
 * For multiple selections, the values are joined with a comma.
 */

/**
 * This converts the project filter attributes into a format that can be used with our Checkbox.
 * It will return a flat list  and the value is the string concatenation of all child ids.
 * For all non-hierarchical filters, the value is the id of the attribute.
 */

const convertToOptionsList = (attributes, attributeId) => {
    let options = [];
    let topLevelOptions = attributes
        .filter((item) => item.id == attributeId)
        .map((attribute) => attribute.sorted_values)[0];

    console.log("Top Level Options", topLevelOptions);

    topLevelOptions.map((topLevelOption) => {
        let option = {
            id: topLevelOption["id"],
            label: topLevelOption["name"],
            value: topLevelOption["id"],
        };

        // push the top level option
        options.push(option);

        let childAttributes = attributes.find(
            (item) => item.parent_value_id == option.id
        )?.sorted_values;
        // do we have child attributes?
        if (childAttributes) {
            option.value = ""; // For groups, value should only include ids of children
            //console.log("--- Child Attributes for ", option.label, childAttributes);
            childAttributes.map((childAttribute) => {
                //console.log("Child Attribute", childAttribute);

                // append to parent option value
                option.group = true;
                option.value = option.value
                    ? `${option.value},${childAttribute["id"]}`
                    : childAttribute["id"];

                options.push({
                    id: childAttribute["id"],
                    label: childAttribute["name"],
                    value: childAttribute["id"],
                    parent: option.id,
                });
            });
        }
    });

    console.log("All available options", options);
    return options;
};

/**
 * Finds the Options within the options list that match the current filter value.
 * The selectedIdValues are a string of comma-separated ids.
 *
 * @param {*} allOptions
 * @param {*} selectedIdValues
 */
const getSelectedOptions = (allOptions, selectedIdValues) => {
    if (!selectedIdValues) {
        return [];
    }
    let selectedOptions = [];
    console.log("---", typeof selectedIdValues);

    selectedIdValues.toString().split(",").map((id) => {
        let option = allOptions.find((item) => item.id == id);
        if (option) {
            selectedOptions.push(option);
        }
    });

    console.log("selected", selectedOptions);

    return selectedOptions;
};


const AttributeFilterCheckbox = ({
                                     filtersParams,
                                     setFiltersParams,
                                     attributes,
                                     filterAttributeId,
                                     label = "Filter",
                                     ...props
                                 }) => {
    if (!attributes) {
        return <div>Project Attributes are required</div>;
    }

    if (!filterAttributeId) {
        return <div>Filter attribute ID is required</div>;
    }
    const filterId = filterAttributeId;

    const options = convertToOptionsList(attributes, filterId);
    let selectedOptions = getSelectedOptions(
        options,
        filtersParams["f"] ? filtersParams["f"][`${filterId}`] : ""
    );

    const handleCheckboxChange = (event, option) => {
        const selectedOptionIds = selectedOptions.map((opt) => opt.id);
        const newSelectedOptionIds = event.target.checked
            ? [...selectedOptionIds, option.id]
            : selectedOptionIds.filter((id) => id !== option.id);

        const newFilterParams = {
            ...filtersParams,
            f: {
                ...filtersParams.f,
                [`${filterId}`]: newSelectedOptionIds.join(","),
            },
            offset: 0,
        };

        setFiltersParams(newFilterParams);
    };

    return (
        <>
            <FormLabel>
                {i18n.translations.filterLocation}
            </FormLabel>
            <div>
                {options.map((option) => (
                    <label
                        key={option.id}
                        style={{ display: "block", cursor: "pointer" }}
                    >
                        <Checkbox
                            checked={selectedOptions.some((opt) => opt.id === option.id)}
                            onChange={(event) => handleCheckboxChange(event, option)}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </>
    );
};

export default AttributeFilterCheckbox;
