import Grid from '@mui/material/Grid';
import {FormLabel, Slider} from "@mui/material";
import React, {useState, useEffect} from "react";
import {i18n} from "../util/i18n";

/**
 * A React component that selectes the workload of a job filter.
 * Uses the MUI Range Slider component underneath.
 * See: https://mui.com/material-ui/react-slider/
 * https://mui.com/material-ui/react-slider/#range-slider
 */

const WorkloadFilterSlider = ({
                                  filtersParams,
                                  setFiltersParams,
                                  min = 10,
                                  max = 100,
                                  steps,
                                  markValues = [10, 40, 80, 100],
                                  labelDisplay = "off",
                                  ...props
                              }) => {
    const [marks, setMarks] = useState([10, 100]);

    useEffect(() => {
        setMarks(filtersParams?.workload || [min, max]);
    }, [filtersParams]);

    const handleWorkloadChange = (event, value) => {
        // keep all filter values, but change the workload (and reset offset to zero)
        setFiltersParams({
            ...filtersParams,
            workload: value,
            offset: 0,
        });
    };

    return (
        <>
            <FormLabel className="left-label">
                {i18n.translations.workloadLabel}
            </FormLabel>
            <Grid marginLeft="15px" marginRight="15px">
                <Slider
                    aria-labelledby="track-pensum-slider"
                    getAriaValueText={(v) => `$v[0] .. $v[1]%`}
                    min={10}
                    max={100}
                    value={filtersParams?.workload ? filtersParams.workload : [10, 100]}
                    marks={marks.map((value) => ({
                        value,
                        label: `${value} %`,
                    }))}
                    step={10}
                    onChange={(e, v) => handleWorkloadChange(e, v)}
                    valueLabelDisplay="off"
                />
            </Grid>
        </>
    );
};

export default WorkloadFilterSlider;