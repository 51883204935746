import React from 'react';
import Grid from '@mui/material/Grid';

import {Card} from "@mui/material";
import {i18n} from "../util/i18n";

const QuickApplyButton = () => {
    const hash = window.location.href;
    const hasFParam = hash.includes('f=10:1088521');
    // Überprüfe, ob der f= Parameter existiert
    if (hasFParam) {
        return null;
    }

    return (
        <Grid container xs={12} sm={6} justifyContent={{ xs: 'initial', sm: 'flex-end' }} marginTop={{ xs: '20px', sm: '0' }}>
            <Card component="a" href={i18n.translations.quickButtonUrl} id="buttonLink" target="_blank">
                {i18n.translations.quickButton}
            </Card>
        </Grid>
    );
};

export default QuickApplyButton;
