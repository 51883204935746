import React, { useState, useEffect } from "react";

import {
    Card,
    CardActions,
    CardContent,
    Fade,
    Grid,
    IconButton,
    Input,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, Skeleton,
    Typography,
} from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useSettings } from "../util/SettingsContext";
import Favourite from "../components/Favourite";
import Grow from "@mui/material/Grow";
import DeleteForever from "@mui/icons-material/DeleteForever";

/**
 * This renders a single job for this project as a "list" item.
 * You may want to use `JobListItem` with children, the "Favourite" component etc.
 *
 * This component will be rendered into a MUI `List`
 * See: https://mui.com/material-ui/react-list/
 * API: https://mui.com/material-ui/api/list/
 *
 * TODO: For Transitions see MUI docs,
 * especially: https://mui.com/material-ui/transitions/#child-requirement
 *
 * @param {*} job
 */
const JobListItemSkeleton = () => {
    const { settings } = useSettings();

    return (
        <Fade in={true} timeout={500}>
            <ListItem className="job-list-item skeleton">
                    <Skeleton
                        variant="rectangular"
                        width={'180px'}
                        height={'60px'}
                    />
                    <Grid container marginLeft={3} marginRight={3} spacing={1}>
                        <Grid item xs={12} sm={12} md={12} marginBottom={'5px'}>
                            <Skeleton
                                variant="rectangular"
                                height={26}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Skeleton
                                variant="rectangular"
                                height={16}
                            />
                        </Grid>
                    </Grid>
                    <Skeleton
                        className="job-list-item-skeleton"
                        variant="circular"
                        width={34}
                        height={30}
                    />
            </ListItem>
        </Fade>
    );
};

const JobGridItemSkeleton = () => {
    const { settings } = useSettings();
    return (
        <Fade in={true} timeout={500}>
            <Grid
                item
                xs={12}
                sm={12}
                md={12 / (settings.jobGridColumns - 1)}
                lg={12 / settings.jobGridColumns}>
                <Card className="job-grid-item skeleton" elevation={0} sx={{height:'100%'}}>
                    <CardContent sx={{ height: "100%" }}>
                        <Grid container sx={12} sm={12} md={12}>
                            <Grid item sx={3} sm={3} md={3}>
                                <Skeleton
                                    variant="rectangular"
                                    width={'100%'}
                                    height={60}
                                />
                            </Grid>
                            <Grid container spacing={1} sx={9} sm={9} md={9}>
                                <Grid item sx={12} sm={12} md={12} marginBottom={'5px'} marginLeft="10px">
                                    <Skeleton
                                        variant="rectangular"
                                        width={'50%'}
                                        height={15}
                                    />
                                </Grid>
                                <Grid item sx={12} sm={12} md={12} marginBottom={'5px'} marginLeft="10px">
                                    <Skeleton
                                        variant="rectangular"
                                        width={'30%'}
                                        height={15}
                                    />
                                </Grid>
                                <Grid item sx={12} sm={12} md={12} marginBottom={'10px'} marginLeft="10px">
                                    <Skeleton
                                        variant="rectangular"
                                        width='100%'
                                        height={30}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Fade>
    );
};

export { JobListItemSkeleton, JobGridItemSkeleton };

// /**
//  * You can do anything here, just make sure to include a unqiue key
//  */
// const dummyItem = ({ job }) => {
//   return <div key={`job-list-item-${job.id}`}>{job.title}</div>;
// };
// export { dummyItem as JobItem };
