import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Unstable_Grid2";

import {i18n} from "../util/i18n";
import {useSettings} from "../util/SettingsContext";
import {useFiltersParams} from "../util/FiltersParamsContext";

import {useFilters} from "../util/FiltersContext";

/**
 * Import your components here
 */
import {Button, Switch, Typography} from "@mui/material";

import TextSearchFilterInput from "../components/TextSearchFilterInput";
import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import ResetFiltersButton from "../components/ResetFiltersButton";
import JobAboSubscriptionModal from "../components/JobAboSubscriptionModal";
import JobAboSubscriptionForm from "../components/JobAboSubscriptionForm";
import JobAboForm from "./form";

/**
 * Main Layout for JobAbo
 */
const JobAboLayout = ({}) => {
    const {settings, setSettings} = useSettings();
    const {data: filtersData} = useFilters();

    // This is what we pass to child components to update the filtersParams
    const {filtersParams, setFiltersParams} = useFiltersParams();

    /**
     * Return or Career center main page
     */
    return (
        <Grid
            className='jobabo'
            container
            spacing={5}
            marginTop={2}
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item xs={12} m={3} sx={{border: "solid 1px #446A91", borderRadius: "40px", padding: "40px 0px"}}>
                <JobAboForm
                    attributes={filtersData.attributes}
                    filtersParams={filtersParams}
                    setFiltersParams={setFiltersParams}
                />
            </Grid>
        </Grid>
    );
};

export default JobAboLayout;
