import React from "react";

import {
    Card,
    CardContent,
    Fade,
    Grid,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";

import {useSettings} from "../util/SettingsContext";

/**
 * This renders a single job for this project as a "list" item.
 * You may want to use `JobListItem` with children, etc.
 *
 * This component will be rendered into a MUI `List`
 * See: https://mui.com/material-ui/react-list/
 * API: https://mui.com/material-ui/api/list/
 *
 * TODO: For Transitions see MUI docs,
 * especially: https://mui.com/material-ui/transitions/#child-requirement
 *
 * @param {*} job
 */
const JobListItem = ({job}) => {
    const {settings} = useSettings();

    return (
        <ListItem key={`${job.id}-list-item`} className="job-list-item">
            <Fade in={true} timeout={500}>
                <ListItemButton component="a" href={job.links.directlink} target="_blank">
                    <img src={`https://pms.imgix.net/${job.szas.sza_company_logo}`} width='100%' />
                    <ListItemText
                        primary={job.attributes[settings.jobAttributes.Location]}
                        secondary={job.title.replace(/<\/?B>/gi, '')}
                    />
                </ListItemButton>
            </Fade>
        </ListItem>
    );
};


const JobGridItem = ({job}) => {
    const {settings} = useSettings();
    const minPensum = job.szas["sza_pensum.min"];
    const maxPensum = job.szas["sza_pensum.max"];
    const pensum = minPensum === maxPensum ? `${maxPensum} %` : `${minPensum} - ${maxPensum} %`;
    return (
        <Grid
            item
            xs={12} sm={12 / settings.jobGridColumns} md={12 / settings.jobGridColumns} lg={12 / settings.jobGridColumns}
            key={`${job.id}-grid-item`}
        >

            <Fade in={true} timeout={500}>
                <Card className="job-grid-item" elevation={0} sx={{width: "100%", height: "100%"}}>
                    <ListItemButton
                        component="a"
                        href={job.links.directlink}
                        sx={{height: "100%"}}
                        target="_blank"
                    >
                        <CardContent sx={{height: "100%"}}>
                            <img src={`https://pms.imgix.net/${job.szas.sza_company_logo}`} width='100%' />
                            <Typography component="div">
                                <ListItemText
                                    primary={job.title.replace(/<\/?B>/gi, '')}
                                />
                                <ListItemText
                                    secondary={pensum + " | " + job.attributes[settings.jobAttributes.Limitation]}
                                />

                                { job.attributes?.[settings.jobAttributes.Institution]?.[0] !== "OHNE" ? (

                                    <ListItemText 
                                    className="list-item-font"
                                    secondary={
                                        job.attributes?.[settings.jobAttributes.Institution] 
                                            ? job.attributes[settings.jobAttributes.Institution]
                                            : ''
                                    }
                                />
                                ) : null }                                
                                <ListItemText className="list-item-font"
                                    secondary={
                                        job.attributes?.[settings.jobAttributes.Medical_Area]?.length 
                                            ? job.attributes[settings.jobAttributes.Medical_Area].join(", ")
                                            : ''
                                    }
                                />
                            </Typography>
                        </CardContent>
                    </ListItemButton>
                </Card>
            </Fade>
        </Grid>
    );
};

export {JobListItem, JobGridItem};

// /**
//  * You can do anything here, just make sure to include a unqiue key
//  */
// const dummyItem = ({ job }) => {
//   return <div key={`job-list-item-${job.id}`}>{job.title}</div>;
// };
// export { dummyItem as JobItem };
