import React from "react";
import {
    Grid,
    List,
    Typography,
} from "@mui/material";
import {useSettings} from "../util/SettingsContext";
import {JobGridItemSkeleton, JobListItemSkeleton} from "../careercenter/jobItemSkeleton";
import {i18n} from "../util/i18n";

/**
 * Renders a list of jobs, based on the JSON object passed in the props.
 * Uses MUI List components, see: https://mui.com/material-ui/react-list/
 *
 * @param {jobs JSON object, as coming from the API} jobs
 * @returns JSX for the JobList
 */
const JobList = ({
                     jobsData,
                     jobPageSize,
                     totalJobs,
                     renderJobItem = (job) => {
                         <Typography key={job.id}>{job.title}</Typography>;
                     },
                     notFoundComponent = <Typography component="h2">{i18n.translations.noJobs}</Typography>,
                     loading = false,
                     disableSkeleton = false,
                     displayAsGrid = false,
                     children,
                     ...props
                 } = props) => {
    const {settings} = useSettings();
    const jobList = jobsData?.jobs ? jobsData.jobs : [];

    if (!Array.isArray(jobList)) {
        return (
            <div key="invalid-jobs">
                Invalid jobs list, expected an array of job objects
            </div>
        );
    }

    if (loading || (!jobsData && !disableSkeleton)) {
        return (
            <>
                {!displayAsGrid && (
                    <List className="job-list">
                        {new Array(settings.jobsPageSize).fill({}).map((item, idx) => (
                            <JobListItemSkeleton
                                key={`skeleton-${idx}`}
                            />
                        ))}
                    </List>
                )}
                {displayAsGrid && (
                    <Grid className="job-grid" container spacing={3}>
                        {new Array(settings.jobsPageSize).fill({}).map((item, idx) => (
                            <JobGridItemSkeleton
                                key={`skeleton-${idx}`}
                            />
                        ))}
                    </Grid>
                )}
            </>
        );
    }

    return (
        <>
            {jobList.length == 0 && (
                <>{notFoundComponent}</>
            )}
            {jobList.length > 0 && !displayAsGrid && (
                <List className="job-list">
                    {jobList.map((job) => {
                        return renderJobItem(job);
                    })}
                </List>
            )}
            {jobList.length > 0 && displayAsGrid && (
                <Grid container alignItems="stretch" className="job-grid" display="block" justifyContent="stretch" paddingBottom="30px" spacing={3}>
                    {jobList.map((job) => {
                        return renderJobItem(job);
                    })}
                </Grid>
            )}
        </>
    );
};

export default JobList;
