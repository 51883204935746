export default {
    // GOLIVE: needs to be set up right for PROD
    baseUrl: "https://ohws.prospective.ch/public/v1/medium/1000666",
    // GOLIVE: needs to be set up right for PROD
    jobAboSubscriptionFormUrl:
        "https://ohws.prospective.ch/public/v1/careercenter/1000666/createJobAbo",

    // This setting is different for local test and when deploying to production.
    // Set it in the .env and .env.production
    routerBasename: process.env.ROUTER_BASENAME || "",
    // not recommended because ugly ../#/.. urls and SEO, but Hashrouting helps with
    // current web server limitations
    // Also, note the location of URL params change for JobAbo etc.
    // BrowserRouter: https://localhost:3000/jobabo?lang=en&q=Foo&f=80:12345 ...
    // HashRouter:   https://localhost:3000/?lang=en&q=Foo&f=80:12345#/jobabo
    useHashRouter: true,

    jobsPageSize: 15,
    showLoadMore: false,

    enableFavourites: false, // enable the favourites feature
    localStorageKeyFavourites: "prospective-jobs-favourites", // key for local storage
    displayAsGrid: true, // enable the job grid view
    jobGridColumns: 1, // number of columns for the job grid view (lg and up)

    autoSubmit: true, // if true, the filters will be submitted automatically when changed
    textSearchFilterDebounceTime: 500, // time in ms to wait before submitting the changes in the TextSearchFilterInput.

    enableCopyToClipboard: true, // if true, there will be an option to copy the job link to the clipboard - only visible with hover
    /**
     * TODO
     * Please add your filders and ids here, as defined for the project.
     * Your components will be able to access these ids via Settings.filterIds['Your filter Name'] or FILTER['Your name']
     */
    filterIds: {
        Profession: 10,
        Location: 50,
    },
    jobAttributes: {
        Workload: 55,
        Limitation: 70,
        Institution: 30,
        Medical_Area: 40
    },
};
