import React from "react";
import Grid from '@mui/material/Grid';

import {Card, ListItemText, Typography} from "@mui/material";
import {i18n} from "../util/i18n";

import { useFiltersParams } from "../util/FiltersParamsContext";
import { useFilters } from "../util/FiltersContext";
import JobAboSubscriptionModal from "../components/JobAboSubscriptionModal";
import JobAboForm from "../jobabo/form";

/* images */
import imageSpontanbewerbung from '../../public/assets/images/footer/Spontanbewerbung.png';
import imagePersonalvermittlerinnen from '../../public/assets/images/footer/Personalvermittlerinnen.png';

const FooterSection = () => {
    const { data: filtersData } = useFilters();

    // This is what we pass to child components to update the filtersParams
    const { filtersParams, setFiltersParams } = useFiltersParams();
    return (
        <Grid container xs={12} gap="30px 0" justifyContent="space-between" marginTop="30px">
            <Grid item xs={12} sm={6} md={4} paddingRight={{xs: "0", sm: "16px"}}>
                <JobAboSubscriptionModal anchor="top" attributes={filtersData.attributes} filtersParams={filtersParams} setFiltersParams={setFiltersParams}>
                    <JobAboForm attributes={filtersData.attributes} filtersParams={filtersParams} setFiltersParams={setFiltersParams} />
                </JobAboSubscriptionModal>
            </Grid>
            <Grid item xs={12} sm={6} md={4} paddingLeft={{xs: "0", sm: "16px"}} paddingRight={{sm: "0", md: "16px"}}>
                <Card className="footerCardItem">
                    <Card component="a" href={i18n.translations.footerSpontanbewerbungUrl} target="_blank">
                        <Typography component="div">
                            <img alt={i18n.translations.altSpontanbewerbung} src={imageSpontanbewerbung}/>
                            <Grid xs={12} paddingBottom="40px" paddingLeft="25px" paddingRight="25px" paddingTop="20px">
                                <ListItemText
                                    primary={i18n.translations.footerSpontanbewerbungTitle}
                                />
                                <ListItemText
                                    secondary={i18n.translations.footerSpontanbewerbungText}
                                />
                            </Grid>
                        </Typography>
                    </Card>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} paddingLeft={{sm: "0", md: "16px"}} paddingRight={{sm: "16px", md: "0"}}>
                <Card className="footerCardItem">
                    <Card component="a" href={i18n.translations.footerPersonalvermittlerinnenUrl} target="_blank">
                        <Typography component="div">
                            <img alt={i18n.translations.altPersonalvermittlerinnen} src={imagePersonalvermittlerinnen}/>
                            <Grid xs={12} paddingBottom="40px" paddingLeft="25px" paddingRight="25px" paddingTop="20px">
                                <ListItemText
                                    primary={i18n.translations.footerPersonalvermittlerinnenTitle}
                                />
                                <ListItemText
                                    secondary={i18n.translations.footerPersonalvermittlerinnenText}
                                />
                            </Grid>
                        </Typography>
                    </Card>
                </Card>
            </Grid>
        </Grid>
    );
};

export default FooterSection;