import React from "react";
import Grid from "@mui/material/Grid";
import {Button, InputBase, Typography} from "@mui/material";

import {i18n} from "../util/i18n";

import JobAboSubscriptionForm from "../components/JobAboSubscriptionForm";
import TextSearchFilterInput from "../components/TextSearchFilterInput";
import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import {useSettings} from "../util/SettingsContext";
/**
 *
 * @param {*} param0
 * @returns
 */
const JobAboForm = ({attributes, filtersParams, setFiltersParams}) => {
    const {settings} = useSettings();
    const FILTER = settings.filterIds;

    return (
        <JobAboSubscriptionForm
            attributes={attributes}
            filtersParams={filtersParams}
            setFilterParams={setFiltersParams}
            className="jobabo-subscription-form"
        >
            <Grid
                container
                spacing={4}
            >
                <Typography component="p">
                    {i18n.translations.jobaboSubscriptionFormIntroduction}
                </Typography>
                <Grid item xs={12} sm={12} md={6} paddingLeft={{xs: "20px", sm: "30px"}}>
                    <InputBase
                        fullWidth
                        id="jobabo_email"
                        name="jobabo_email"
                        placeholder={i18n.translations.jobaboEmailLabel}
                        required
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} paddingLeft={{xs: "20px", sm: "30px"}}>
                    <InputBase
                        fullWidth
                        id="jobabo_bezeichnung"
                        name="jobabo_bezeichnung"
                        placeholder={i18n.translations.jobaboNameLabel}
                        required
                        variant="filled"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} paddingLeft={{xs: "20px", sm: "30px"}}>
                    <TextSearchFilterInput
                        filtersParams={filtersParams}
                        label={i18n.translations.searchPlaceholderJobAbo}
                        setFiltersParams={setFiltersParams}
                    />
                </Grid>
                <Grid item xs={12} md={6} paddingLeft={{xs: "20px", sm: "30px"}}>
                    <AttributeFilterAutocomplete
                        label={i18n.translations.filterProfession}
                        filterAttributeId={FILTER.Profession}
                        attributes={attributes}
                        filtersParams={filtersParams}
                        setFiltersParams={setFiltersParams}
                    ></AttributeFilterAutocomplete>
                </Grid>
                <Grid item xs={12} md={6} paddingLeft={{xs: "20px", sm: "30px"}}>
                    <AttributeFilterAutocomplete
                        label={i18n.translations.filterLocation}
                        filterAttributeId={FILTER.Location}
                        attributes={attributes}
                        filtersParams={filtersParams}
                        setFiltersParams={setFiltersParams}
                    ></AttributeFilterAutocomplete>
                </Grid>
                <Grid item xs={12} md={6} display={{xs: "none", sm: "block"}}></Grid>
                <Grid item xs={12} md={6} marginTop={2}>
                    <Button
                        variant="contained"
                        type="submit"
                    >
                        {i18n.translations.jobaboModalButtonLabel}
                    </Button>
                </Grid>
            </Grid>
        </JobAboSubscriptionForm>
    );
};

export default JobAboForm;