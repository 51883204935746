import Grid from '@mui/material/Grid';
import {Card, Popover, Stack, Typography, Link} from '@mui/material';
import React, {useEffect, useState} from "react";

import {useFiltersParams} from "../util/FiltersParamsContext";
import {i18n} from "../util/i18n";
import {useSettings} from "../util/SettingsContext";
import {useFetchUrl} from "../util/UrlParams";
import {useFilters} from "../util/FiltersContext";
import {useLoadJobs} from "../util/QueryService";
import {useDebounce} from "ahooks";

/* images */
import iconSearchFilter from '../../public/assets/images/icons/search-filter.svg';
import imageCancel from "../../public/assets/images/icons/cancel.svg";

/**
 * Import your components here
 */
import JobListPagination from "../components/JobListPagination";
import FooterSection from "../components/Footer";
import JobListLoadMore from "../components/JobListLoadMore";
import AttributeFilterAutocomplete from "../components/AttributeFilterAutocomplete";
import AttributeFilterCheckbox from "../components/AttributeFilterCheckbox";
import ResetFiltersButton from "../components/ResetFiltersButton";
import TextSearchFilterInput from "../components/TextSearchFilterInput";
import WorkloadFilterSlider from "../components/WorkloadFilterSlider";
import QuickApplyButton from "../components/QuickApplyButton";

// Items are typically project specific
import JobList from "../components/JobList";
import {JobGridItem, JobListItem} from "./jobItem";
import Button from "@mui/material/Button";

/**
 * Main Layout for CC
 */
const Layout = ({}) => {
    const {settings, setSettings} = useSettings();
    const {data: filtersData} = useFilters();

    // This is what we pass to child components to update the filtersParams
    const {filtersParams, setFiltersParams} = useFiltersParams();
    // useDebounce updates the debouncedParams state variable,
    // 200ms after filtersParams has been changed (avoid flickering)
    const debouncedParams = useDebounce(filtersParams, {
        wait: 100,
    });

    // internal state will get updated with a delay after an update.
    // Changes to this will trigger a reload of the jobs list
    const [internalParams, setInternalParams] = useState(filtersParams);

    // Flag, if a "submit" has been triggered. If settings.autoSubmit = false,
    // changes to filtersParams only get applied (and trigger a reload of new job list)
    // if this has been set to true. Flag gets cleared after every submit
    const [triggerSubmit, setTriggerSubmit] = useState(false);

    // Dependend filter for Standort
    const [selectedLocation, setSelectedLocation] = useState(null);

    // create the fetch url from the current internalParams and load the job list from the backend
    const url = useFetchUrl(internalParams);
    const {data: jobsData} = useLoadJobs(url);

    useEffect(() => {
        // if autoSubmit, we apply the changed immediatly
        if (settings.autoSubmit) {
            setInternalParams(filtersParams);
        } else {
            // if NOT autoSubmit, we apply the changes only once triggerSubmit flag has been set
            if (triggerSubmit) {
                setInternalParams(filtersParams);
            }
        }
        // in any case, clear the submit trigger flag
        setTriggerSubmit(false);
    }, [debouncedParams, triggerSubmit]);

    // shortcut to reference filterIds from settings
    const FILTER = settings.filterIds;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popoverFilterWrapper' : undefined;
    const hash = window.location.href;
    const hasFParam = hash.includes('f=');

    /**
     * Return or Career center main page
     */
    return (
        <>
            {/* Careercenter Section */}
            <Grid container xs={12} id="career-center">
                {/* header section */}
                <Grid container xs={12} md={8}>
                    <Grid marginBottom="40px">
                        <Card component="span">
                            {i18n.translations.loginText}
                        </Card>
                        <Card component="a" href={i18n.translations.loginUrl} id="labelLink" target="_blank">
                            {i18n.translations.loginLabel}
                        </Card>
                    </Grid>
                    <Grid container xs={12} display={{xs: "block", sm: "none"}}>
                        <Grid item xs={12} marginBottom="30px">
                            <TextSearchFilterInput
                                filtersParams={filtersParams}
                                label={i18n.translations.searchPlaceholder}
                                setFiltersParams={setFiltersParams}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container xs={12} sm={6} alignItems="center">
                            <Typography className="list-item-font">
                                {i18n.translations.currentJobs} {jobsData?.total}
                            </Typography>
                        </Grid>
                        <QuickApplyButton />
                    </Grid>
                </Grid>
                {/* main section */}
                <Grid container gap="32px 0" justifyContent="space-between" marginTop="30px">
                    {/* Filter Section: Mobile */}
                    <Grid container xs={12} display={{xs: "block", sm: "none"}} id="sectionFilter">
                        <Grid item aria-describedby={id} onClick={handleClick} alignItems="center" display="flex" gap="16px">
                            <Card>
                                <img alt={i18n.translations.altSearchFilter} src={iconSearchFilter}/>
                            </Card>
                            <Card component="h1">
                                {i18n.translations.searchTitle}
                            </Card>
                        </Grid>
                    </Grid>
                    <Popover anchorEl={anchorEl} anchorOrigin={{horizontal: 'left', vertical: 'top'}} id={id} onClose={handleClose} open={open}>
                        <Stack direction={"column"}>
                            <div className="drawer-header">
                                <Grid container xs={12}>
                                    <Grid container xs={11} alignItems="center">
                                        <Grid item alignItems="center" display="flex" gap="16px">
                                            <Card>
                                                <img alt={i18n.translations.altSearchFilter} src={iconSearchFilter}/>
                                            </Card>
                                            <Card component="h1">
                                                {i18n.translations.searchTitle}
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={1} justifyContent="flex-end">
                                        <Button onClick={handleClose}>
                                            <img alt={i18n.translations.altSearchFilter} src={imageCancel}/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="drawer-body">
                                <Grid item xs={12} marginTop="20px">
                                    <WorkloadFilterSlider
                                        filtersParams={filtersParams}
                                        setFiltersParams={setFiltersParams}
                                    />
                                </Grid>
                                <Grid item xs={12} marginTop="20px">
                                    <AttributeFilterAutocomplete
                                        attributes={filtersData.attributes}
                                        filterAttributeId={FILTER.Profession}
                                        filtersParams={filtersParams}
                                        label={i18n.translations.filterProfession}
                                        mode={"single"}
                                        setFiltersParams={setFiltersParams}
                                    />
                                </Grid>
                                <Grid item xs={12} marginTop="20px">
                                    <AttributeFilterCheckbox
                                        attributes={filtersData.attributes}
                                        filterAttributeId={FILTER.Location}
                                        filtersParams={filtersParams}
                                        label={i18n.translations.filterLocation}
                                        setFiltersParams={setFiltersParams}
                                    />
                                </Grid>
                                <Grid item xs={12} marginTop="30px">
                                    <ResetFiltersButton
                                        filtersParams={filtersParams}
                                        setFiltersParams={(value) => {
                                            setFiltersParams(value);
                                            setSelectedLocation(null);
                                            setTriggerSubmit(true);
                                        }}
                                        setTriggerSubmit={setTriggerSubmit}
                                    />
                                </Grid>
                            </div>
                        </Stack>
                    </Popover>
                    {/* Filter Section: Desktop */}
                    <Grid container xs={12} md={3.5} display={{xs: "none", sm: "block"}}  order={{sm: 0, md: 1}}>
                    <Grid item>
                    <Grid container display={{xs: "none", sm: "block"}} id="sectionFilter">
                        <Grid item alignItems="center" display="flex" gap="16px">
                            <Card>
                                <img alt={i18n.translations.altSearchFilter} src={iconSearchFilter}/>
                            </Card>
                            <Card component="h1">
                                {i18n.translations.searchTitle}
                            </Card>
                        </Grid>
                        <Grid item xs={12} marginTop="30px">
                            <TextSearchFilterInput
                                filtersParams={filtersParams}
                                label={i18n.translations.searchPlaceholder}
                                setFiltersParams={setFiltersParams}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop="30px">
                            <WorkloadFilterSlider
                                filtersParams={filtersParams}
                                setFiltersParams={setFiltersParams}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop="20px">
                            <AttributeFilterAutocomplete
                                attributes={filtersData.attributes}
                                filterAttributeId={FILTER.Profession}
                                filtersParams={filtersParams}
                                label={i18n.translations.filterProfession}
                                mode={"single"}
                                setFiltersParams={setFiltersParams}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop="20px">
                            <AttributeFilterCheckbox
                                attributes={filtersData.attributes}
                                filterAttributeId={FILTER.Location}
                                filtersParams={filtersParams}
                                label={i18n.translations.filterLocation}
                                setFiltersParams={setFiltersParams}
                            />
                        </Grid>
                        <Grid item xs={12} marginTop="30px">
                            <ResetFiltersButton
                                filtersParams={filtersParams}
                                setFiltersParams={(value) => {
                                    setFiltersParams(value);
                                    setSelectedLocation(null);
                                    setTriggerSubmit(true);
                                }}
                                setTriggerSubmit={setTriggerSubmit}
                            />
                        </Grid>
                    </Grid>
                        <Grid item xs={12} marginTop="50px" id="zivildienststellen">
                            <Card component="p">
                                {i18n.translations.zivildienststellen} <Link target="_blank" rel="noreferrer" className="linkText" href={i18n.translations.zivildienststellenLink}>{i18n.translations.mehrerfahren}</Link>...
                            </Card>
                        </Grid>
                    </Grid>
                    </Grid>
                    {/* Jobs Result Section */}
                    <Grid container xs={12} md={8}>
                        {/* Jobs List Section */}
                        <JobList
                            displayAsGrid={settings.displayAsGrid}
                            jobsData={jobsData}
                            jobsPageSize={settings.jobsPageSize}
                            renderJobItem={(job) => {
                                return settings.displayAsGrid ? (
                                    <JobGridItem key={job.id} job={job}/>
                                ) : (
                                    <JobListItem key={job.id} job={job}/>
                                );
                            }}
                            totalJobs={jobsData?.total}
                        />
                        {/* Pagination Section */}
                        <Grid item xs={12}>
                            {!settings.showLoadMore && (
                                <JobListPagination
                                    filtersParams={filtersParams}
                                    setFiltersParams={(value) => {
                                        setFiltersParams(value);
                                        setTriggerSubmit(true);
                                    }}
                                    totalJobs={jobsData?.total}
                                />
                            )}
                            {settings.showLoadMore && (
                                <JobListLoadMore
                                    filtersParams={filtersParams}
                                    setFiltersParams={(value) => {
                                        setFiltersParams(value);
                                        setTriggerSubmit(true);
                                    }}
                                    totalJobs={jobsData?.total}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/* footer section: Card Redirects */}
                <Grid xs={12}>
                    <FooterSection/>
                </Grid>
            </Grid>
        </>
    );
};

export default Layout;
