import React, { useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { i18n } from "./util/i18n";
//import Iframe from "react-iframe";
import { useSettings } from "./util/SettingsContext";
import IframeResizer from "iframe-resizer-react";

//
// see: https://github.com/davidjbradshaw/iframe-resizer-react

const IFramePage = (props) => {
  const { settings } = useSettings();
  const ref = useRef(null);
  const [messageData, setMessageData] = useState(undefined);

  const onResized = (data) => {
    setMessageData(data);
    console.log("react-iframe resized:", data);
  };

  const onMessage = (data) => {
    setMessageData(data);
    console.log("react-iframe onMessage", data);
    ref.current.sendMessage("Hello back from parent page");
  };

  return (
    <>
      <Typography variant="h1">Above IFrame</Typography>
      <IframeResizer
        log
        id="myIframe"
        inPageLinks
        forwardRef={ref}
        onMessage={onMessage}
        onResized={onResized}
        src={settings.routerBasename || "/"}
        width="100%"
      />
      <Typography variant="h1">Below IFrame</Typography>
    </>
    // <Grid
    //   container
    //   margin={2}
    //   spacing={2}
    //   padding={4}
    //   direction="row"
    //   justifyContent="space-between"
    //   alignItems="stretch"
    //   sx={{ border: 1, borderColor: "blue" }}
    // >
    //   <Grid item xs={12} margin={2} textAlign={"center"} sx={{ border: 1 }}>
    //     <Typography variant="h2">Header</Typography>
    //   </Grid>
    //   <Grid item xs={1} margin={2} textAlign={"center"} sx={{ border: 1 }}>
    //     <Typography>Left</Typography>
    //   </Grid>
    //   <Grid
    //     item
    //     xs={8}
    //     margin={2}
    //     padding={2}
    //     sx={{ border: 1, borderColor: "green" }}
    //   >
    //     <IframeResizer
    //       log
    //       id="myIframe"
    //       inPageLinks
    //       forwardRef={ref}
    //       onMessage={onMessage}
    //       onResized={onResized}
    //       src={settings.iFrameUrl}
    //       height="1300px"
    //       width="100%"
    //     />
    //     {/* <Iframe
    //       src={settings.iFrameUrl}
    //       width="100%"
    //       height="1300px"
    //       scrolling="no"
    //     ></Iframe> */}
    //   </Grid>
    //   <Grid item xs={1} margin={2} textAlign={"center"} sx={{ border: 1 }}>
    //     <Typography>Right</Typography>
    //   </Grid>

    //   <Grid item xs={12} margin={2} textAlign={"center"} sx={{ border: 1 }}>
    //     <Typography>Footer</Typography>
    //   </Grid>
    // </Grid>
  );
};

export default IFramePage;
