import * as React from "react";
import {useSettings} from "../util/SettingsContext";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import DoubleArrowBackIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoubleArrowForwardIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
} from "@mui/material";

import PropTypes from "prop-types";
import {i18n} from "../util/i18n";

/**
 * This is a reusable pagination component for Job Lists.
 * It uses the filtersParams object to get the current offset and limit (= page size) and also the total number of jobs for the query.
 * It displays a Pagination control from Material UI.
 * If you pass in a setFilterParams function, it will be called when the user clicks on a page number with the updated offset property
 * (so that you can then fetch the new page of jobs).
 *
 * @param {} filtersParams The current filtersParams object. Important properties: offset, limit
 * @param {*} setFilterParams A useState setter function for the filtersParams object
 * @param {*} totalJobs Total number of jobs for the query. This is used to calculate the number of pages.
 * @param {*} loading if True the component will display a loading indicator instead of the pagination control
 * @param {*} props Will be spread onto the Pagination component. You can use this e.g. for custom styling via sx={{...}} property.
 *
 * @returns JobListPagination component
 */
const JobListPagination = ({
                               jobsPageOptions,
                               filtersParams,
                               setFiltersParams,
                               totalJobs,
                               loading = false,
                               ...props
                           }) => {
    const {settings, setSettings} = useSettings();

    const currentPage = filtersParams?.offset / filtersParams?.limit + 1;
    const totalPages = Math.ceil(totalJobs / filtersParams?.limit);
    console.log("totalPages", totalPages);

    let empty = !(totalPages >= 1);

    let error =
        !filtersParams ||
        !setFiltersParams ||
        filtersParams.offset < 0 ||
        filtersParams.limit <= 0 ||
        filtersParams.offset > totalJobs;

    const handleOnChange = (event, page) => {
        //console.log("Going to page:", actualPage);
        setFiltersParams({
            ...filtersParams,
            offset: (page - 1) * filtersParams.limit,
            submitted: true,
        });
        document.getElementById("root").scrollIntoView();
    };

    const handleLoadMore = () => {
        //console.log("Going to page:", actualPage);
        setFiltersParams({
            ...filtersParams,
            offset: 0,
            limit: filtersParams.limit + settings.jobsPageSize,
            submitted: true,
        });
    };

    return (
        <>
            {error && (
                <div>
                    ERROR: Invalid or missing required properties for JobListPagination
                </div>
            )}
            {(loading || empty) && <Pagination hidden={true}/>}

            {totalPages >= 1 && (
                <>
                    <Grid
                        className="pagination-wrapper"
                        container
                        spacing={4}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Grid item xs={12} sm={12} md={6}>
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                disabled={totalPages == 1}
                                onChange={handleOnChange}
                                hidden={totalPages <= 1}
                                size="small"
                                {...props}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{ previous: DoubleArrowBackIcon, next: DoubleArrowForwardIcon }}
                                        {...item}
                                    />
                                )}
                            />
                        </Grid>
                        {jobsPageOptions && (
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl
                                    sx={{minWidth: 140, textAlign: "center"}}
                                    size="small"
                                >
                                    <InputLabel>{settings.jobsPageSize} / page</InputLabel>
                                    <Select
                                        sx={{p: 0, m: 0}}
                                        onChange={(event) => {
                                            console.log("Changing page size", event);
                                            setSettings({
                                                ...settings,
                                                jobsPageSize: event.target.value,
                                            });
                                            // also update filters params
                                            setFiltersParams({
                                                ...filtersParams,
                                                limit: event.target.value,
                                                offset: 0,
                                            });
                                        }}
                                    >
                                        {jobsPageOptions.map((option) => {
                                            return (
                                                option && (
                                                    <MenuItem key={option} value={option}>
                                                        {option} / page
                                                    </MenuItem>
                                                )
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

/**
 * Proptypes help us (and our IDEs, and Storybook) to understand the properties of this component.
 * While optional, a god idea in general to have.
 * See docs: https://reactjs.org/docs/typechecking-with-proptypes.html
 * See: https://github.com/facebook/prop-types
 */
JobListPagination.propTypes = {
    filtersParams: PropTypes.object.isRequired,
    setFiltersParams: PropTypes.func.isRequired,
    totalJobs: PropTypes.number,
    loading: PropTypes.bool,
    //  ...PaginationProps
};

export default JobListPagination;
